.mainContainer {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: gray;
  padding: 0px;
  margin-top: 50px;
  position: relative;
}

.dropDownContainer {
  padding: 15px;
}

.dropDown {
  width: 80%;
}

.addButton {
  width: 20%;
}

.brandHeader {
  font-size: 25px;
}

.closeIcon {
  position: relative;
  color: gray;
  cursor: pointer;
  left: 580px;
  z-index: 1000;

  &:hover {
    color: #3d3a3b;
  }
}
