.mainTable {
  width: 100%;
  margin-top: 40px;
  border-collapse: collapse;
}

.mainTd {
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 2px;
  padding: 10px 0px 10px 0px;
  font-weight: bold;
  text-transform: uppercase;
}

.subTd {
  padding: 10px 0px 10px 0px;
}

.tableWithSolidTopBorder {
  width: 100%;
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 2px;
}

.tableBottomBorder {
  width: 100%;
  border-top-style: solid;
  border-top-width: 2px;
  border-top-color: black;
}
