footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;
  background-color: #e0e0e0;
}

.spacing {
  height: 170px;
}
