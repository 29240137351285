.tabel {
  width: 100%;
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 2px;
}

.brandName {
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  padding: 10px 0px 10px 0px;
}

.productLine {
  width: 50%;
  padding: 5px 0px 5px;
}

.salesPerson {
  width: 50%;
  text-align: right;
}

.productLineTable {
  width: 100%;
  padding-left: 15px;
}
