.paper {
  padding: 15px 0px 15px 0px;
  box-shadow: none;
  border-radius: 0;
}

.topContainer {
}

.dropDown {
  width: 80%;
}

.addButton {
  width: 20%;
}
