@font-face {
  font-family: "AdelleBSOffice";
  src: local("AdelleBSOffice"),
    url(./fonts/AdelleBSOffice.ttf) format("truetype");
}

h1 {
  font-size: 26px;
  font-family: AdelleBSOffice, Adelle, Arial;
  margin: 0px 0px 8px 0px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

h2 {
  margin: 80px 0px 15px 0px;
  font-family: AdelleBSOffice, Adelle, Arial;
  font-size: 15px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

h3 {
  font-size: 14px;
  font-family: AdelleBSOffice, Adelle, Arial;
  margin: 25px 0px 10px 0px;
  color: black;
}

body {
  margin: 0;
  font-family: AdelleBSOffice, Adelle, Arial;
  /* font-size: 15px; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

p {
  line-height: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-container {
  max-width: 620px;
  margin: auto;
}

.min-margin-container {
  margin: 0px 10px 0px 10px;
  padding-bottom: 2.5rem; /* Footer height */
}

.MuiInputBase-root fieldset {
  border-radius: 0;
  border-color: black;
  margin: 0px;
}

/* legend {
  width: initial !important
} */

.css-nnbavb {
  width: auto;
}

.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75) !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 12px) scale(1);
}

.MuiInputLabel-root {
  line-height: 1;
}
.MuiOutlinedInput-input {
  padding: 10px;
}

.MuiOutlinedInput-root input {
  height: 17px;
}

.MuiSelect-root {
  height: 17px;
  background-color: white;
}

.MuiInputBase-root {
  font-size: 1.1428571428571428rem;
  box-sizing: border-box;
  line-height: 1.1875em;
}

@media (max-width: 622px) {
  .MuiOutlinedInput-root input {
    height: 27px;
  }

  .MuiSelect-root {
    height: 27px;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 16px) scale(1);
    padding-right: 7px;
  }
}

.MuiOutlinedInput-notchedOutline legend {
  font-size: 0.65em !important;
}

.MuiInputLabel-animated {
  z-index: 1000;
}

#root {
  position: relative;
  min-height: 100vh;
}

.MuiTableCell-root {
  background-color: #f2f2f2 !important;
  font-family: AdelleBSOffice !important;
  font-size: 1rem !important;
}

.MuiTableCell-head {
  font-size: 0.8571428571428571rem !important;
  line-height: 1.5rem !important;
}

.MuiTypography-body1 {
  font-size: 16px !important;
  font-family: AdelleBSOffice !important;
}

.MuiToolbar-regular {
  background-color: #f2f2f2;
}

.MuiToolbar-gutters {
  padding-left: 16px;
}

.sendButton {
  width: 160px;
  margin-top: 80px;
}

.MuiSelect-select {
  /* min-width: 16px; */
  min-height: auto !important;
  height: 17px !important;
}

.MuiSelect-select:focus {
  background-color: white;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.textIconContainer {
  margin: 40px 0px 30px 0px;
}

.textItem {
  display: inline-block;
  margin: 0;
}

.iconItem {
  float: right;
  cursor: pointer;
}
